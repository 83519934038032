<template>
  <div class="pages">
    <div class="report-title">
      <div class="title">
        <span class="line"></span>
        <span>我的报备</span>
      </div>
    </div>
    <div class="report-tab">
      <div class="tab" :class="{active:index === tab.selected}" v-for="(item , index) in tab.list" :key="item.id"
           @click="handleTabClick(index)">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="report-list" v-loading="loading">
      <div class="empty" v-if="list.length === 0">暂无数据</div>
      <router-link :to="`/MyReportInfo?id=${item.id}`" class="list" v-for="(item,index) in list" :key="index">
        <div class="name">
          <span>{{ item.client_name }}</span>
        </div>
        <div class="item">
          <div class="title">
            <span>手机号码</span>
          </div>
          <div class="content">
            <span v-if="item.client_phone_type === 1">{{ item.client_phone }}</span>
            <span v-if="item.client_phone_type === 2">
              {{ item.client_phone_prefix }}****{{ item.client_phone_suffix }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>推荐项目</span>
          </div>
          <div class="content">
            <span>{{ item.project.name }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>经纪人</span>
          </div>
          <div class="content">
            <span>{{ item.broker_name }} {{ item.broker_phone }}</span>
          </div>
        </div>
        <div class="item">
          <div class="title">
            <span>报备进度</span>
          </div>
          <div class="content">
            <span>{{ item.type_text }}</span>
          </div>
        </div>
      </router-link>
    </div>
    <div class="paging-info">
      <div class="pagination">
        <div class="num" @click="handlePageChange(item)" :class="{active:item === page.current}"
             v-for="item in page.last" :key="item">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="paging">
        <div class="cut" v-if="page.current !== 1" @click="handlePrevPageClick">
          <span class="left"></span>
        </div>
        <div class="cut" v-if="page.current !== page.last && page.last !== 0" @click="handleNextPageClick">
          <span class="right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyReport',
  data() {
    return {
      tab: {
        selected: 0,
        list: [
          {
            id: 1,
            name: '未到访',
          },
          {
            id: 2,
            name: '到访',
          },
          {
            id: 3,
            name: '认购',
          },
          {
            id: 4,
            name: '签约',
          },
          {
            id: 5,
            name: '结佣',
          },
          {
            id: 100,
            name: '失效',
          },
        ]
      },
      page: {
        current: 1,
        last: 1,
      },
      list: [],
      loading: false,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true
        let res = await this.http({
          url: '/api/my/report',
          method: 'GET',
          params: {
            type: this.tab.list[this.tab.selected].id,
            page: this.page.current
          }
        })
        this.page.current = res.data.data.page.current
        this.page.last = res.data.data.page.last
        this.list = res.data.data.list
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleTabClick(index) {
      if (index === this.tab.selected) {
        return
      }
      this.tab.selected = index
      this.page.current = 1
      this.page.last = 1
      this.fetchData()
    },
    handlePrevPageClick() {
      this.page.current--
      this.fetchData()
    },
    handleNextPageClick() {
      this.page.current++
      this.fetchData()
    },
    handlePageChange(item) {
      this.page.current = item
      this.fetchData()
    }
  }
}
</script>

<style scoped lang="scss">
@import "MyReport.scss";
</style>
